<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
          <el-form
        ref="search"
        :inline="true" class="searchBoxForm" :model="paramsData">
        <el-form-item label="时间段" prop="equipmentStatus">
          <el-date-picker
            @change="search1"
            size="mini"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
            
          </el-date-picker>
        </el-form-item>
        <el-form-item label="巡检区域" prop="equipmentStatus">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="pageParams.networkId"
            placeholder="请选择巡检区域"
            
          >
          <!-- @change="searchList" -->
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
                   :label="item.networkName"
            :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="pageParams.userId"
            placeholder="请选择巡线员"
            
          >
          <!-- @change="searchList" -->
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态" prop="equipmentStatus">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="pageParams.taskGenerateStatus"
            placeholder="请选择状态"
            
          >
          <!-- @change="searchList" -->
            <el-option
              v-for="item in optionsL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
          clearable
          size="mini"
            placeholder="请输入任务编号"
            v-model="pageParams.searchText"
            style="width:170px"
          >
          </el-input>
        </el-form-item>
       <el-form-item label="" prop="" >
        <el-button
          size="mini"
          type="primary"
          @click="searchList"
          style="margin-left:10px;"
          >搜索</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resize"
          >重置</el-button
        ><el-button
          size="mini"
          type="primary"
          @click="exportDetil"
          v-if="$anthButtons.getButtonAuth('deriveUrgencytask')"
          >导出</el-button
        >
         </el-form-item>
      </el-form>
      </div>
    </div>
    <el-divider style="color: #f2f2f2"></el-divider>
    <div>
      <!-- <div class="table-height"> -->
      <div class="tableTool">
        <!-- <span style="float:right;margin:0.5%;"> 
          <el-button type="primary" size="mini" @click="goPath('/home/addSample')">添加</el-button>
        </span> -->
        <el-button
          size="mini"
          type="primary"
          class="addButton"
          v-if="$anthButtons.getButtonAuth('addUrgencytask')"
          @click="addItem()"
          >添加</el-button>
      </div>
      <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            class="table-height-in"
            :stripe="true"
            height="calc(100vh - 462px)"
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign:'center',
              background: '#f8f8f9',
            }"
          >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="left"
        >
        </el-table-column>
        <el-table-column
            prop="taskCode"
            label="任务编号"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="networkName"
            label="巡检区域"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="eqTypeName"
            label="巡检设备"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="checkingPoint"
            label="巡检点数"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
         <el-table-column
            prop="finishPush"
            label="已完成数"
            align="left"
            show-overflow-tooltip
          >
          <template  slot-scope="{ row }">
            <span @click="showList(row.id, row.taskGenerateStatus)" style="color:#409EFF;cursor: pointer">{{row.finishPush}}</span>
          </template>
        </el-table-column>
         <el-table-column
            prop="executorPeopleName"
            label="巡线员"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
         
         <el-table-column
            prop="used"
            label="执行时间"
            align="left"
            show-overflow-tooltip
          >
          <template slot-scope="{ row }">
            {{String(row.taskDurationStartTime).replaceAll("-", "/")}} - {{String(row.taskDurationEndTime).replaceAll("-", "/")}}
          </template>
        </el-table-column>
        <el-table-column
            prop="used"
            label="任务状态"
            align="left"
            show-overflow-tooltip
          >
          <template slot-scope="{ row }">
          <el-tag v-if="row.taskStatusName == '未开始'" size="mini" style="color: #F4C41D;border: 1px solid #F0DCA8;background: #FFFCF6;">
              {{ row.taskStatusName }}
          </el-tag> 
          <el-tag v-else :type='taskStatus(row.taskStatusName)' size="mini">
              {{ row.taskStatusName }}
          </el-tag>  
        </template>
        </el-table-column>
        <el-table-column
            prop="taskRemark"
            label="备注"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        
        <el-table-column label="操作" align="left" >
          <template slot-scope="{row}">
            <div class="operateTool">
              <el-button
            type="primary"
            size="mini"
            v-if="$anthButtons.getButtonAuth('remarkUrgencytask')"
            @click="IsOpenFun(4, row)"
            >备注</el-button
          >
          <el-button type="primary" plain 
          v-if="row.taskGenerateStatus<2 && $anthButtons.getButtonAuth('editUrgencytask')"  
          @click="addItem(row)" size="mini"
            >编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10,30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    </div>
    <!-- 备注 -->
    <el-dialog :visible.sync="remarkBool" width="460px" @close="CloseChangePlan" :close-on-click-modal='false'>
       <el-form :model="remarkData"   ref="DeviceData" label-width="80px" :rules="rules">
        <el-form-item label="备注" prop="taskRemark">
          <el-input
            style="width:100%"
            maxlength="200"
            type="textarea"
            name="limitLength"
            show-word-limit
            placeholder="请输入备注"
            v-model="remarkData.taskRemark"
          ></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="submitRemark" type="primary"  >确 认</el-button>
        <el-button size="small" @click="remarkBool = false"  >取 消</el-button>
      </span>
    </el-dialog>
    <!-- 添加事件任务 -->
     <el-dialog :title="titles" :visible.sync="redeployVisible" @close="CloseChangePlan" width="460px" :close-on-click-modal='false'>
      <el-form :model="DeviceData"   ref="DeviceData" label-width="80px" :rules="rules">
      <el-form-item label="巡检区域" prop="networkId" v-if="DeviceData.taskGenerateStatus==0||!DeviceData.taskGenerateStatus">
          <el-select style="width: 338px" v-model="DeviceData.networkId">
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
              :label="item.networkName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检设备" prop="eventTypeIdList" v-if="DeviceData.taskGenerateStatus==0||!DeviceData.taskGenerateStatus">
          <!-- <el-select style="width: 300px" v-model="DeviceData.eqType">
            <el-option
              v-for="item in eqTypeArray"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            
            </el-option>
          </el-select> -->
          <el-cascader
                  style="width: 338px"
                  :options="eqTypeArray"
                  v-model="DeviceData.eventTypeIdList"
                  :props="{  label:'name' , value:'id'  }"
                  separator='>'
                  clearable
                  collapse-tags
                  @change="deformation">
                </el-cascader>
        </el-form-item>
        <el-form-item label="巡线员" prop="taskUseres">
          <el-select style="width: 338px" v-model="DeviceData.taskUseres" multiple>
            <el-option
              v-for="item in excutorDownArray1"
              :key="item.id"
              :label="item.username+'('+item.mobile+')'"
              :value="item.id.toString()">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行时间" prop="taskDurationStartTimeArr">
          <el-date-picker v-if="DeviceData.taskGenerateStatus==0||!DeviceData.taskGenerateStatus"
            v-model="DeviceData.taskDurationStartTimeArr"
            type="daterange"
            @change="DatePickerFun"
            style="width: 338px"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <div v-else>
            <el-date-picker style="display:inline-block;width: 162px;" readonly value-format="yyyy-MM-dd" v-model="DeviceData.taskDurationStartTime"  type="date" placeholder="开始日期"></el-date-picker>
          -
          <el-date-picker style="display:inline-block;width: 162px;" :picker-options="pickerOptions" v-model="DeviceData.taskDurationEndTime" value-format="yyyy-MM-dd" type="date" placeholder="结束日期"></el-date-picker>
          </div>
          
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="redeployVisible = false" >取 消</el-button>
        <el-button size="small" @click="submitRedeploy" type="primary">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getNetwork,
  getUserList,
  taskpage,
  getDict,
  updatetask,
  updatetask1,
  getRemark,excelTask1
} from "../../RequestPort/maintenance/task";
import { exportMethod } from '../../common/js/exportExcel'
import {
  templatePageList,
  templateGeneralStatusToggle
} from "@/RequestPort/dict/cycle";
import {
  selectDictListByPid
} from "../../RequestPort/business/userFile.js";
import pageMixins from "@/utils/pageMixins";
import { dicByType } from "../../RequestPort/maintenance";
export default {
  name:'Sample',
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
        taskType:3,
        networkId:''
      }, //  分页查询参数
      tableData: [], //  表格数据
       total:0,
      title:'',
      dialogVisible:false,//启用弹框
      value:'',
      // 用户分类
      options:[],
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'禁用',value:0},
        {label:'启用',value:1},
      ],
       // 片区字典，巡检区域
      selectDownArray: [],
      // 巡线人员
      excutorDownArray: [],
      excutorDownArray1:[],
      //任务状态 0-未开始 1-进行中 2-部分完成3 已完成
        optionsL: [{
          value: '0',
          label: '未开始'
        }, {
          value: '1',
          label: '进行中'
        }, 
         {
          value: '2',
          label: '已完成'
        },{
          value: '3',
          label: '未完成'
        }],
        paramsData:{},
        remarkBool:false,
        remarkData:{taskRemark:'',id:''},
        rules: {
        networkId: [{ required: true, message: "请选择巡检区域", trigger: "change" }],
        eventTypeIdList:[{ required: true, message: "请选择巡检设备", trigger: "change" }],
        taskUseres: [{ required: true, message: "请选择巡线员", trigger: "change" }],
        taskDurationStartTimeArr: [{ required: true, message: "请选执行日期", trigger: "change" }],
         taskRemark:[
          { required: true, message: "备注不能为空", trigger: "blur" },
        ]
      },
      redeployVisible:false,
      DeviceData:{taskType:3,
        taskDurationStartTime:'',
        taskDurationEndTime:'',
         taskDurationStartTimeArr:[],
        eqType:'',networkId:'',taskUser:'',taskUseres:[],eventTypeIdList:[]},
      eqTypeArray:[],
     
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //若是没有后面的-8.64e7就是不能够选择今天的
        },
      },
      titles:'',
      DataPicker:[],
      eventTypeIdList:[]
    };
  },
  mounted() {
    // 巡检区域字典，片区下拉
    getNetwork().then((e) => {
      this.selectDownArray = e.data;
    });
    // 获取巡线员
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    // 获取巡线员排除
    getUserList({type:1}).then((e) => {
      this.excutorDownArray1 = e.data;
    });
    this.getList()
    selectDictListByPid({pid: 460}).then(res => {
      this.options = res.data
    })
    // getDict({pid:720}).then(res=>{
    //   this.eqTypeArray = res.data
    // })
    //设备类型
    dicByType({parentCode:'sblx',level:3}).then(res => {
        if(res.code == 200) {
            this.eqTypeArray = res.data
        } else {
            this.$message.error(res.msg)
        }
    })
  },
  methods: {
    /**
     * 事件类型 选择事件
     */
    deformation(){
      this.DeviceData.eqType = this.DeviceData.eventTypeIdList[1]
      // this.getList()
    },
    /**
     * 任务状态
     */
    taskStatus(start){
      if(start == '进行中') return 'warning' 
      if(start == '已转派') return '' 
      if(start == '已完成') return 'success' 
      if(start == '未完成') return 'danger' 
    },
    /**
     * 导出
     */
    exportDetil(){
      excelTask1(this.pageParams).then(res=>{
        exportMethod(res, '紧急任务')
      })
    },
    showList(id,taskGenerateStatus){
      // this.$router.push({ path: '/home/device/details', query: { id: id,screcd:2 } })
      let chakan = this.$router.resolve({
					path: '/home/device/details',      //跳转目标窗口的地址
					query:  { id: id,screcd:2,taskGenerateStatus } 
				})
				window.open(chakan.href);
    },
    resize(){
      this.pageParams ={
        current: 1,
        size: 100,
        taskType:3
      }
      this.getList()
      this.DataPicker = []
    },
    DatePickerFun(){
      if(this.DeviceData.taskDurationStartTimeArr.length>0){
        this.DeviceData.taskDurationStartTime=this.DeviceData.taskDurationStartTimeArr[0]
        this.DeviceData.taskDurationEndTime=this.DeviceData.taskDurationStartTimeArr[1]
      }else{
        this.DeviceData.taskDurationStartTime=''
        this.DeviceData.taskDurationEndTime=''
      }
    },
    /**
     * 打开新增编辑
     */
    addItem(row){
      this.DeviceData = {
        networkId:'',
        taskUser:[],
        taskUseres:[],
        eventTypeIdList:[],
        taskDurationStartTimeArr:[]
      }
      if(!row){
        this.titles = '添加';
      }else{
        this.titles = '编辑';
        this.DeviceData = {...this.DeviceData,...row}
        if(this.DeviceData.taskUser) this.DeviceData.taskUseres = this.DeviceData.taskUser.split(',')
        this.eqTypeArray.forEach(el => {
          el.children.forEach(item => {
            if(item.id == this.DeviceData.eqType) {
              this.DeviceData.eventTypeIdList = [el.id,this.DeviceData.eqType]
            }
          })
        })
        // this.DeviceData.taskDurationStartTime = row.taskDurationStartTime;
        // this.DeviceData.taskDurationEndTime = row.taskDurationEndTime
        // this.DeviceData.eqType = row.eqType
        // this.DeviceData.networkId = row.networkId
        // this.DeviceData.taskUser = row.taskUser
        // this.DeviceData.id = row.id
        // this.DeviceData.taskGenerateStatus = row.taskGenerateStatus
        this.DeviceData.taskDurationStartTimeArr = [row.taskDurationStartTime,row.taskDurationEndTime]
        // console.log(row);
      }
      this.redeployVisible = true;
    },
    /**
     * 添加紧急任务
     */
    submitRedeploy(){
      this.$refs["DeviceData"].validate((valid) => {
        if (valid) {
         this.DeviceData.taskUser = this.DeviceData.taskUseres //.join(',')
        updatetask1(this.DeviceData).then(res=>{
          this.$message.success(res.msg)
          this.getList(1)
          this.redeployVisible = false
        })
        }
      })
    },
    /**
     * 关闭
     */
    CloseChangePlan(){
      this.DeviceData.taskDurationStartTimeArr = []
      this.$refs["DeviceData"].resetFields()
    },
    search1(){
      if(this.DataPicker&&this.DataPicker.length>0){
        this.pageParams.startTime = this.DataPicker[0]
        this.pageParams.endTime = this.DataPicker[1]
      }else{
       delete  this.pageParams.startTime
       delete  this.pageParams.endTime
      }
      this.pageParams.current = 1
      // this.getList()
    },
    /**
     * 备注弹窗
     */
    IsOpenFun(e, row) {
      this.remarkBool = true
      if(this.$refs.remarkData){
        this.$refs.remarkData.resetFields()
      }
      this.remarkData.id = row.id
      this.remarkData.taskRemark = row.taskRemark
    },
    /**
     * 备注提交
     */
    submitRemark(){
      this.$refs.DeviceData.validate((valid) => {
        if (valid) {
          getRemark(this.remarkData).then(res => {
            this.$message.success("添加备注成功");
            this.getList();
            this.remarkBool = false
          })
        }
      })
    },
    /**
     * 获取列表
     */
    getList(val){
      if(val){
        this.pageParams.current = 1
      }
      taskpage(this.pageParams).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 更改状态
     */
    stateUpdata(val){
      let that = this
      this.$confirm('是否启用此模板，启用后将自动停用该类型其他模板', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          templateGeneralStatusToggle({id:val}).then(res => {
            that.$message({
              type: 'success',
              message: res.data
            });
            that.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    /**
     * 搜索
     */
    searchList(val){
      this.getList(1)
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchBar {
  // .searchBoxForm {
  //   display: flex;
  //   align-items: center;
  // }
  overflow: inherit;
  .el-form-item{
  margin-bottom: 0;
}
}

.tableTool{    margin-top: 10px;}
//  .searchItem {
//       // margin-bottom: 1%;
//       //  margin-left: 1%;
//     }
// .info-item {
//   margin-bottom: 20px;
// }
// .label {
//   color: #909399;
// }
// .content-item {
//   color: #444444;
// }
// .SearchBar {
//   /* border: 1px solid #ecedf1; */
//   border-radius: 5px;
//   margin: 10px;
//   padding-top: 1%;

//   background-color: #ffffff;
// }
.all {
  // background-color:#f3f4f8;
  height:auto
}
.content {
  background: #ffffff;
  width: 100%;
  // border-radius: 6px;
  // overflow:auto;
  height: calc(100vh - 285px);
}
.content-text {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diaBtn{
  width: 100px;
  height: 40px;
  margin-bottom: 15px;
}
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
.el-pagination{
        margin: 15px 0px 15px;
  }
</style>